console.log('Vite ⚡️ Rails')

// Import Turbo Hotwire
import "@hotwired/turbo-rails"

// Import Stimulus controllers
import "../javascript/controllers"

// Import stylesheets
import '../stylesheets/application.css'
